import React, { useState, MouseEvent, useEffect, ChangeEvent } from 'react'
import ReactPlayer from 'react-player'
import { Collapse, Slider } from '@material-ui/core'
import screenfull from 'screenfull'

import { PlayerProgress, Caption } from './MediaPlayer'
import useStyles, { sliderStyles } from './MediaControlsStyles'

import VideoPlayIcon from './assets/Play.png'
import VideoPauseIcon from './assets/Pause.png'
import SeekReverseIcon from './assets/Rewind.png'
import SeekForwardIcon from './assets/Fastforward.png'
import CCIcon from './assets/Caption.png'
import ExpandIcon from './assets/Expand.png'
import CollapseIcon from './assets/Collapse.png'

const CONTROLS_TIMEOUT = 3000

const toMinutes = (time: number): string => {
  const minutes = Math.floor(time / 60)
  let seconds = Math.floor(time % 60).toString()
  if (seconds.length === 1) {
    seconds = '0' + seconds
  }
  return minutes + ':' + seconds
}

interface Props {
  playing: boolean
  setPlaying: (play: boolean) => void
  videoRef?: React.MutableRefObject<ReactPlayer | null>
  componentRef?: React.MutableRefObject<HTMLDivElement | null>
  progress: PlayerProgress
  ready: boolean
  enableFullscreen?: boolean
  englishCaptionsURL?: string
  spanishCaptionsURL?: string

  selectCaptions: (captions: Caption) => void
}

const MediaControls: React.FC<Props> = ({
  setPlaying,
  playing,
  componentRef,
  videoRef,
  progress,
  enableFullscreen = true,
  ready,
  englishCaptionsURL,
  spanishCaptionsURL,
  selectCaptions,
}) => {
  const [showTimeout, setShowTimeout] = useState<NodeJS.Timeout>()
  const [showControls, setShowControls] = useState<boolean>(false)
  const [showLanguageSelector, setShowLanguageSelector] = useState(false)
  const [timePlayed, setTimePlayed] = useState<string>('0:00')
  const [duration, setDuration] = useState<string>('0:00')
  const { controls, controlsContainer, button, icon, transport, spacer, languageSelector, languageButton } = useStyles()
  const slider = sliderStyles()

  useEffect(() => {
    if (videoRef?.current && ready) {
      const mediaDuration = videoRef.current.getDuration()
      setDuration(toMinutes(mediaDuration))
    }
    return () => {
      if (showTimeout) clearTimeout(showTimeout)
    }
  }, [videoRef, ready])

  useEffect(() => {
    setTimePlayed(toMinutes(progress.playedSeconds))
  }, [progress])

  const startHideControlsTimer = () => {
    if (showTimeout) {
      clearTimeout(showTimeout)
    }
    setShowTimeout(setTimeout(setShowControls, CONTROLS_TIMEOUT, false))
  }

  const onContainerClick = () => {
    startHideControlsTimer()
    if (!showControls) {
      setShowControls(true)
    } else {
      setPlaying(!playing)
    }
  }

  const onTransportClick = (e: MouseEvent) => {
    e.stopPropagation()
    startHideControlsTimer()
  }

  const onSeekForward = () => {
    if (videoRef?.current) {
      const durationSeconds = videoRef.current.getDuration()
      const newTime = progress.playedSeconds >= durationSeconds - 10 ? durationSeconds : progress.playedSeconds + 10
      videoRef.current.seekTo(newTime, 'seconds')
    }
  }
  const onSeekRewind = () => {
    if (videoRef?.current) {
      const newTime = progress.playedSeconds <= 10 ? 0 : progress.playedSeconds - 10
      videoRef.current.seekTo(newTime, 'seconds')
    }
  }

  const onFullscreen = () => {
    if (screenfull.isEnabled && componentRef?.current) {
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request(componentRef.current)
      }
    }
  }

  const onCCClick = () => {
    setShowLanguageSelector(!showLanguageSelector)
  }
  const selectLanguage = (language: Caption) => {
    setShowLanguageSelector(false)
    selectCaptions(language)
  }

  const onPlayPause = () => {
    setPlaying(!playing)
  }

  const onSeek = (e: ChangeEvent<unknown>, newValue: number | number[]) => {
    e.stopPropagation()
    startHideControlsTimer()
    if (videoRef?.current) videoRef.current.seekTo(newValue as number)
  }

  return (
    <div className={controlsContainer} onClick={onContainerClick}>
      <Collapse in={showControls} className={controls}>
        <Slider
          defaultValue={0}
          value={progress.played}
          step={0.01}
          min={0}
          max={1}
          onChange={onSeek}
          classes={slider}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        />
        <div className={transport} onClick={onTransportClick}>
          <button onClick={onPlayPause} className={button}>
            <img src={playing ? VideoPauseIcon : VideoPlayIcon} alt="" className={icon} />
          </button>
          <button onClick={onSeekRewind} className={button}>
            <img src={SeekReverseIcon} alt="" className={icon} />
          </button>
          <button onClick={onSeekForward} className={button}>
            <img src={SeekForwardIcon} alt="" className={icon} />
          </button>
          <div className={spacer} />
          <div>
            <p>
              {timePlayed} / {duration}
            </p>
          </div>
          {(englishCaptionsURL || spanishCaptionsURL) && (
            <button onClick={onCCClick} className={button}>
              <img src={CCIcon} alt="" className={icon} />
            </button>
          )}
          {enableFullscreen && (
            <button onClick={onFullscreen} className={button}>
              <img
                src={screenfull.isEnabled && screenfull.isFullscreen ? CollapseIcon : ExpandIcon}
                alt=""
                className={icon}
              />
            </button>
          )}
          {
            <Collapse in={showLanguageSelector && showControls}>
              <div className={languageSelector}>
                {englishCaptionsURL && (
                  <button className={languageButton} onClick={() => selectLanguage('en')}>
                    English
                  </button>
                )}
                {spanishCaptionsURL && (
                  <button className={languageButton} onClick={() => selectLanguage('es')}>
                    Español
                  </button>
                )}
                <button className={languageButton} onClick={() => selectLanguage('off')}>
                  Off
                </button>
              </div>
            </Collapse>
          }
        </div>
      </Collapse>
    </div>
  )
}

export default MediaControls
