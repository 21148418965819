import * as THREE from 'three'
import { ForceGraph3DInstance } from '3d-force-graph'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import triModel from './assets/triangle.glb'
import particleImage from './assets/star.png'

import { Memories, Mem, Node, Link, GraphData } from './MemoryGraph'

const objLoader = new GLTFLoader()
const textureLoader = new THREE.TextureLoader()
let obj = new THREE.Object3D()
objLoader.load(
  triModel,
  function (gltf) {
    obj = gltf.scene.children[0]
  },
  undefined,
  function (error) {
    console.error(error)
  },
)

interface MemWorld {
  id: number
  title: 'C Street' | 'Earth' | 'Eemia' | 'Numina' | 'Ossuary'
  color: string
  created_at: string
  updated_at: string
}

export const createGraphData = (memories: Memories, soloMems: Array<Mem>, userMemIds: Array<number>): GraphData => {
  const userMems = new Set(userMemIds)

  const memoryNodes = new Map<string, Array<Node>>()
  const worlds: Array<MemWorld> = []

  for (let i = 0; i < memories.length; i++) {
    let nodesToAdd: Array<Node> = []

    const memory = memories[i]

    const memoryMems = memory.mems
    /**
     * Compare user inventory against the Memory's mems
     */
    for (let j = 0; j < memoryMems.length; j++) {
      const mem = memoryMems[j]
      if (userMems.has(mem.id)) {
        // Add to array of nodes we will add to the graph
        nodesToAdd.push(memToNode(mem, memory.world))
      }
    }

    /**
     * If nodesToAdd length equals the required mem array length
     * then the memory is complete. Only add a node to represent
     * the completed memory.
     */
    if (nodesToAdd.length > 0 && nodesToAdd.length === memoryMems.length) {
      nodesToAdd = [
        {
          id: `${memory.Title}-complete`,
          name: `${memory.Title}-complete`,
          visible: true,
          image: memory.still.OffsiteImage?.url || '',
          color: memory.world.color,
          meta: {
            type: 'memory',
            world: memory.world.title,
            title: memory.Title,
            description: memory.Description,
            mediaSrc: memory.Video?.OnsiteVideoURL || memory.URL,
            vimeoId: memory.Video?.VimeoID,
            characterName: memory.Rememberer?.Name,
            characterAvatarSrc: memory.Rememberer?.Avatar?.OnsiteImageLink,
            recallCode: undefined,
            englishCaptionsURL: memory.Video?.englishCaptionsURL,
            spanishCaptionsURL: memory.Video?.spanishCaptionsURL,
          },
        },
      ]
    }

    // create array of worlds as we go
    if (!worlds.find((world) => world.title === memory.world.title)) {
      worlds.push({ ...memory.world })
    }

    memoryNodes.set(memory.Title, nodesToAdd)
  }

  /**
   * Set color with mem's own world property here
   * because these mems have a 'complete' world object property.
   * This is a cms quirk
   */
  const noMemoryMemNodes = soloMems.map((mem) => memToNode(mem, mem.world))
  const memoryMemNodes = makeMemoryNodes(memoryNodes)

  const centralNode = {
    id: 'center',
    name: 'center',
    visible: false,
    image: '',
    color: '',
    meta: null,
  }

  const worldNodes: Array<Node> = worlds.map((world) => ({
    id: `${world.title}-world`,
    name: `${world.title}-world`,
    visible: false,
    image: '',
    color: '',
    meta: null,
  }))

  const combinedNodes = [centralNode, ...noMemoryMemNodes, ...memoryMemNodes, ...worldNodes]

  // Attach solo mems to world nodes
  const noMemoryMemsToWorldLinks = noMemoryMemNodes.map((node) => ({
    source: `${node.meta?.world}-world`,
    target: node.id,
    value: 1,
    color: node.color,
    curve: 0,
    visible: false,
    rotation: 0,
  }))

  // Attach world nodes to central node
  const worldToCenterNodeLinks = worldNodes.map((node) => ({
    source: 'center',
    target: node.id,
    value: 1,
    color: node.color,
    curve: 0,
    visible: false,
    rotation: 0,
  }))

  const memoryLinks = makeMemoryLinks(memoryNodes)

  const combinedLinks = [...memoryLinks, ...noMemoryMemsToWorldLinks, ...worldToCenterNodeLinks]

  return {
    nodes: combinedNodes,
    links: combinedLinks,
  }
}

const memToNode = (mem: Mem, world: MemWorld, visible = true): Node => ({
  id: mem.Title,
  name: mem.Title,
  visible,
  image: mem.Still.OffsiteImage?.url || '',
  color: world.color,
  meta: {
    type: 'mem',
    title: mem.Title,
    world: world.title,
    description: mem.Description,
    mediaSrc: mem.Animation.OffsiteImage?.url || mem.Still.OffsiteImage?.url,
    characterName: mem.Rememberer?.Name,
    characterAvatarSrc: mem.Rememberer?.Avatar?.OffsiteImage?.url,
    recallCode: mem.recallCode,
  },
})

const makeMemoryNodes = (memoryNodes: Map<string, Array<Node>>): Array<Node> => {
  let nodeArray: Array<Node> = []
  for (const [_, nodes] of memoryNodes) {
    nodeArray = nodeArray.concat(nodes)
  }

  return nodeArray
}

const makeMemoryLinks = (memoryNodes: Map<string, Array<Node>>): Array<Link> => {
  const linkArray: Array<Link> = []
  for (const [_, nodes] of memoryNodes) {
    // link to all other nodes in array

    if (nodes.length < 1) continue

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]

      // filter out self and parent node
      const neighborNodes = nodes.filter((n) => n.id !== node.id)

      // This makes links from each node bend a different way
      const rotationDirection = i % 2 == 0 ? 1 : -1

      //Link to neighbor nodes
      for (const neighborNode of neighborNodes) {
        linkArray.push({
          source: node.id,
          target: neighborNode.id,
          value: 1,
          color: node.color,
          curve: 0.3,
          visible: true,
          rotation: rotationDirection * Math.PI * (10 / 6),
        })
      }

      linkArray.push({
        source: `${node.meta?.world}-world`,
        target: node.id,
        value: 1,
        color: node.color,
        curve: 0,
        visible: false,
        rotation: 0,
      })
    }
  }

  return linkArray
}

export const createParticles = (scene: THREE.Scene): void => {
  const particlesGeometry = new THREE.BufferGeometry()

  const count = 2000

  const positions = new Float32Array(count * 3) // Multiply by 3 because each position is 3 values (x, y, z)

  for (let i = 0; i < count * 3; i++) {
    positions[i] = Math.random() * 2000 - 1000
  }

  particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

  const particleTexture = textureLoader.load(particleImage)

  // Configure material
  const particlesMaterial = new THREE.PointsMaterial({
    map: particleTexture,
    size: 40,
    sizeAttenuation: true,
    color: '#a6e5ff',
    transparent: true,
    alphaMap: particleTexture,
    alphaTest: 0.001,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
  })

  // Create Points
  const particles = new THREE.Points(particlesGeometry, particlesMaterial)

  scene.add(particles)
}

export const createNode = (node: Record<string, any>): THREE.Object3D => {
  const sprite = new THREE.Sprite()
  const material = new THREE.SpriteMaterial()
  sprite.name = node.id
  const copy = obj.clone()
  copy.scale.set(25, 25, 1)
  textureLoader.load(node.image, function (imgTexture) {
    material.map = imgTexture
    material.transparent = true
    sprite.material = material

    if (node.id.includes('complete')) {
      sprite.scale.set(20, 20, 1)
      copy.name = node.id
      imgTexture.encoding = THREE.sRGBEncoding
      imgTexture.flipY = false

      // scale the texture up a little bit
      imgTexture.repeat.set(0.8, 0.8)

      // eyeball an offset for our scaling
      imgTexture.offset = new THREE.Vector2(0.1, 0.1)

      copy.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = new THREE.MeshBasicMaterial({
            map: imgTexture,
            transparent: true,
            side: THREE.DoubleSide,
          })
        }
      })
    } else {
      sprite.scale.set(20, 20, 1)
    }
  })

  return node.id.includes('complete') ? copy : sprite
}

export const zoomCameraToNode = (
  node: Record<string, any>,
  Graph: ForceGraph3DInstance,
  nodeFocusCameraPositionUpdateTime: number,
): void => {
  // Aim at node from outside it
  const distance = 70
  const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z)
  const coords = { x: node.x, y: node.y, z: node.z }
  Graph.cameraPosition(
    { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
    coords, // lookAt ({ x, y, z })
    nodeFocusCameraPositionUpdateTime, // ms transition duration
  )
}
