import { Button, DialogTitle, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import MemoWidgetMessagePanel from './MemoWidget-MessagePanel'
import { Memo } from './MemoWidget'
import VerticallyCentered from '../layout/VerticallyCentered'

export interface MemoWidgetModalContentProps {
  memos: ReadonlyArray<Memo>
  onClose: () => void
  onAnyClick?: () => void
  onActiveMemo?: (memoId: number) => void
}

const dialogHeightVh = 85
const headerHeightPx = 130

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    height: `calc(${dialogHeightVh}vh)`,
  },
  closeBtn: {
    position: 'absolute',
    margin: 10,
  },
  closeIcon: {
    fontSize: '7rem',
  },
  header: {
    height: `${headerHeightPx}px`,
    textAlign: 'center',
    width: '100%',
  },
  title: {
    fontSize: '4rem',
    fontWeight: 700,
    letterSpacing: '2%',
  },
  messagePanel: {
    height: `calc(${dialogHeightVh}vh - ${headerHeightPx}px)`,
    flexWrap: 'nowrap',
    marginTop: -6,
    borderTop: `1px solid ${theme.palette.secondary.dark}`,
    borderColor: theme.palette.secondary.dark,
  },
}))

/**
 * The main content of the Mem-Os message system modal. Contains the header and message panel body.
 */
const MemoWidgetModalContent: React.FC<MemoWidgetModalContentProps> = ({
  onClose,
  onAnyClick,
  onActiveMemo,
  memos,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {/* Close icon */}
      <Button className={classes.closeBtn} onClick={onClose}>
        <ClearIcon className={classes.closeIcon} />
      </Button>

      {/* Modal Title */}
      <DialogTitle>
        <VerticallyCentered className={classes.header}>
          <Typography variant="h2" color="secondary" className={classes.title}>
            MEMos
          </Typography>
        </VerticallyCentered>
      </DialogTitle>

      {/* Modal content */}
      <MemoWidgetMessagePanel
        memos={memos}
        className={classes.messagePanel}
        onAnyClick={onAnyClick}
        onActiveMemo={onActiveMemo}
      />
    </div>
  )
}

export default MemoWidgetModalContent
