import React, { useEffect, useRef } from 'react'
import { createBreakpoint, useWindowSize } from 'react-use'
import { Fab, makeStyles, Typography } from '@material-ui/core'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import * as THREE from 'three'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import ForceGraph, { ForceGraph3DInstance } from '3d-force-graph'

import { VignetteShader } from './shaders/VignetteShader'
import { createGraphData, createParticles, createNode, zoomCameraToNode } from './graphUtils'

import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'
import VerticallyCentered from '../layout/VerticallyCentered'

const useBreakpoint = createBreakpoint()

export interface MemoryGraphProps {
  memories: Memories
  soloMems: Array<Mem>
  userMemIds: Array<number>
  onNodeClick?: (node: Node, graphData: GraphData) => void
  onBackgroundClick?: (event: MouseEvent) => void
  setActiveNodeId?: (id: string) => void
  activeNodeId?: string
  nodeFocusCameraRotationSpeed?: number
  nodeFocusCameraPositionUpdateTime?: number
  detailViewOpen?: boolean
}

const useStyles = makeStyles((theme) => ({
  fab: () => ({
    position: 'fixed',
    top: 120,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
    zIndex: theme.zIndex.tooltip,
  }),
}))

const MemoryGraph = React.forwardRef<ForceGraph3DInstance, MemoryGraphProps>((props, ref) => {
  const {
    memories,
    soloMems,
    userMemIds,
    setActiveNodeId,
    onNodeClick,
    onBackgroundClick,
    activeNodeId,
    nodeFocusCameraRotationSpeed = 0.25,
    nodeFocusCameraPositionUpdateTime = 1500,
  } = props
  const graphParent = useRef<HTMLDivElement>(null)
  const breakpoint = useBreakpoint()
  const classes = useStyles()

  const Graph = (ref || useRef<ForceGraph3DInstance>()) as React.MutableRefObject<ForceGraph3DInstance | null>
  const graphData = useRef<GraphData>()
  const scene = useRef<THREE.Scene>()
  const camera = useRef<THREE.Camera>()

  let graphControls: Record<string, any>

  const { width } = useWindowSize()

  // Adjust zoom level after scene is loaded
  THREE.DefaultLoadingManager.onLoad = () => {
    resetZoom()
  }

  const resetZoom = (): void => {
    // determine how many nodes are visible to set zoom level
    if (!graphData.current) return
    const zoomLevel = graphData.current.nodes?.filter((node) => node.visible === true).length > 3 ? 280 : 300
    Graph.current?.zoomToFit(300, zoomLevel)
  }

  // Handle activeNode prop updates
  useEffect(() => {
    if (!activeNodeId || !Graph.current) return

    const nodes = Graph.current?.graphData().nodes
    const node = nodes?.find((node) => node.id === activeNodeId)

    if (!node) return

    zoomCameraToNode(node, Graph.current, nodeFocusCameraPositionUpdateTime)
  }, [activeNodeId])

  // Build the Graph
  useEffect(() => {
    if (!graphParent.current) return

    const _onNodeClick = (node: Node) => {
      setActiveNodeId && setActiveNodeId(node.id)

      onNodeClick && graphData.current && onNodeClick(node, graphData.current)
    }
    // Consider using memo
    graphData.current = createGraphData(memories, soloMems, userMemIds)

    // If we already have a Graph, update the data to re-heat the simulation
    if (Graph.current) {
      Graph.current.graphData(graphData.current)
      return
    }

    // build the graph
    Graph.current = ForceGraph({ controlType: 'orbit' })(graphParent.current)
      .graphData(graphData.current)
      .showNavInfo(false)
      .width(width)
      .linkColor('color')
      .linkWidth(1)
      .linkResolution(10)
      .linkCurvature('curve')
      .linkOpacity(1)
      .linkCurveRotation('rotation')
      .nodeVisibility('visible')
      .linkVisibility('visible')
      .linkOpacity(0.7)
      .nodeColor('color')
      .d3AlphaDecay(0.09)
      .d3VelocityDecay(0.5)
      // @ts-expect-error
      .onNodeClick(_onNodeClick)
      .nodeThreeObject(createNode)
      .onBackgroundClick(onBackgroundClick || (() => undefined))

    // Set link distance
    Graph.current?.d3Force('link')?.distance((link: { target: { id: string }; source: { id: string } }) => {
      const { target, source } = link
      if (source.id === 'center') return 60
      return target.id.includes('complete') ? 55 : 40
    })

    scene.current = Graph.current?.scene()
    camera.current = Graph.current?.camera()

    const renderer = Graph.current?.renderer()
    renderer.setPixelRatio(window.devicePixelRatio)

    /** Post Processing **/
    //.36 1.5 .1
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 0.4, 1.5, 0.1)
    const vignettePass = new ShaderPass(VignetteShader)

    Graph.current?.postProcessingComposer().addPass(bloomPass)
    Graph.current?.postProcessingComposer().addPass(vignettePass)

    /** Control Settings **/
    graphControls = Graph.current?.controls()

    graphControls.maxDistance = 1000

    graphControls.enablePan = false

    graphControls.rotateSpeed = 0.5

    graphControls.zoomSpeed = 0.5

    graphControls.autoRotateSpeed = nodeFocusCameraRotationSpeed

    // create particles
    createParticles(scene.current)

    renderer.setAnimationLoop(() => {
      const time = Date.now()
      if (!scene.current) return
      for (let i = 0; i < scene.current.children.length; i++) {
        const object = scene.current.children[i]

        // rotate particle system
        if (object instanceof THREE.Points) {
          object.rotation.y = time * 0.000008
        }

        // billboard memory nodes
        if (object.children.length > 0) {
          object.children.forEach((child) => {
            if (child.name.includes('complete')) {
              if (camera.current) child.rotation.setFromRotationMatrix(camera.current.matrix)
            }
          })
        }
      }
    })
    // Keeping track of array props length for now. We can memo-ize in the parent if need be
  }, [graphParent.current, breakpoint, memories.length, soloMems.length, userMemIds.length])

  return (
    <div
      style={{
        display: 'flex',
        background: 'linear-gradient(0deg, rgba(38,20,85,1) 39%, rgba(145,60,133,1) 100%)',
      }}
    >
      <div id="3d-graph" className="graph" ref={graphParent} style={{ flexGrow: 1 }} />

      <Fab variant="extended" className={classes.fab} onClick={resetZoom}>
        <HorizontalGrid spacing={1}>
          <GridItem>
            <VerticallyCentered spacing={0} style={{ lineHeight: 0 }}>
              <ZoomOutMapIcon />
            </VerticallyCentered>
          </GridItem>
          <GridItem>
            <Typography variant="button">zoom&nbsp;out</Typography>
          </GridItem>
        </HorizontalGrid>
      </Fab>
    </div>
  )
})

export interface Node {
  id: string
  name: string
  visible: boolean
  image: string
  color: string
  meta: null | {
    type: 'mem' | 'memory'
    title: string
    world: 'C Street' | 'Earth' | 'Eemia' | 'Numina' | 'Ossuary'
    description?: string
    mediaSrc?: string
    vimeoId?: string
    characterName?: string
    characterAvatarSrc?: string
    recallCode?: string
    englishCaptionsURL?: string
    spanishCaptionsURL?: string
  }
}

export interface Link {
  source: string
  target: string
  value: number
  color: string
  curve: number
  visible: boolean
  rotation: number
}

export interface GraphData {
  nodes: Array<Node>
  links: Array<Link>
}

interface MemBase {
  id: number
  Title: string
  Description?: string
  Rememberer?: {
    id: number
    Name: string
    Description?: string
    Avatar?: {
      OnsiteImageLink?: string
      OffsiteImage?: CMSImage
    }
  }
  world: {
    id: number
    title: 'C Street' | 'Earth' | 'Eemia' | 'Numina' | 'Ossuary'
    color: string
    created_at: string
    updated_at: string
  }
}

export interface Mem extends MemBase {
  Animation: {
    id: number
    OnsiteImageLink?: string
    OffsiteImage?: CMSImage
  }
  Still: {
    id: number
    OnsiteImageLink?: string
    OffsiteImage?: CMSImage
  }
  recallCode: string
}

interface CMSImage {
  id: number
  name: string
  alternativeText: string
  caption: string
  width: number
  height: number
  url: string
  formats: {
    [key: string]: {
      url: string
      width: number
      height: number
    }
  }
}

export interface Memory extends MemBase {
  mems: Array<Mem>
  URL: string
  Video: {
    id: number
    OnsiteVideoURL: string
    englishCaptionsURL?: string
    spanishCaptionsURL?: string
    VimeoID?: string
  }
  still: {
    id: number
    OnsiteImageLink?: string
    OffsiteImage?: CMSImage
  }
}

export type Memories = Array<Memory>

export default MemoryGraph
