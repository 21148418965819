import React, { MouseEventHandler, useEffect } from 'react'
import { useIdle } from 'react-use'

import CountDown from './CountDown'

const noop = (note: string) => console.debug(note + ': session timer not initialized')

const LOGOUT_DELAY = 20 // time until logout warning in s
const WARNING_DURATION = 10 // length of logout warning in s

type Timer = {
  blockers: Set<string>
  time: number
  idle: boolean
}

export type SessionContextValue = {
  start: (unBlock?: string) => void
  stop: (block?: string) => void
}

export const SessionContext = React.createContext<SessionContextValue>({
  start: () => noop('start'),
  stop: () => noop('stop'),
})

interface Props {
  children: React.ReactNode
  active: boolean
  logout: () => void
  delay?: number
  duration?: number
}

const SessionTimer = ({ children, active, logout, delay, duration }: Props): React.ReactElement => {
  const isIdle = useIdle(1000)
  const [timer, setTimer] = React.useState<Timer>({
    blockers: new Set(),
    time: delay || LOGOUT_DELAY,
    idle: false,
  })
  const [countDown, setCountDown] = React.useState<boolean>(false)

  useEffect(() => {
    setTimer((currentTimer) => ({
      blockers: currentTimer.blockers,
      time: isIdle ? currentTimer.time : delay || LOGOUT_DELAY,
      idle: isIdle,
    }))
  }, [isIdle])

  const decrementTime = () => {
    setTimer((currentTimer) => {
      if (!currentTimer.blockers.size && currentTimer.idle) {
        return {
          blockers: currentTimer.blockers,
          time: currentTimer.time - 1,
          idle: currentTimer.idle,
        }
      }
      return currentTimer
    })
  }

  useEffect(() => {
    const interval = setInterval(decrementTime, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (active) {
      start('active')
    } else {
      stop('active')
    }
    return () => stop('active')
  }, [active])

  useEffect(() => {
    if (!timer.time) {
      setCountDown(false)
      stop('logout')
      logout()
    } else if (timer.time <= (duration || WARNING_DURATION)) {
      setCountDown(true)
    }
  }, [timer])

  const start = (unblock?: string) => {
    setTimer((currentTimer) => {
      if (unblock) currentTimer.blockers.delete(unblock)
      return {
        time: delay || LOGOUT_DELAY,
        blockers: currentTimer.blockers,
        idle: currentTimer.idle,
      }
    })
    setCountDown(false)
  }

  const stop = (block?: string) => {
    setTimer((currentTimer) => {
      if (block) currentTimer.blockers.add(block)
      return {
        blockers: currentTimer.blockers,
        time: delay || LOGOUT_DELAY,
        idle: currentTimer.idle,
      }
    })
    setCountDown(false)
  }

  const endSession: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    stop('logout')
    logout()
  }

  return (
    <SessionContext.Provider value={{ start, stop }}>
      {children}
      {countDown && <CountDown time={timer.time} endSession={endSession} continueSession={start} />}
    </SessionContext.Provider>
  )
}

export default SessionTimer
