import React from 'react'
import NavBarOffset from '../utils/NavBarOffset'
import GridItem from './GridItem'
import VerticalGrid from './VerticalGrid'
import Container, { ContainerProps } from './Container'
import BackgroundImage from '../utils/BackgroundImage'
import { useTheme } from '@material-ui/core'

export interface ContainerWithNavBarProps extends ContainerProps {
  className?: string
  navbar: React.ComponentType | React.FC
}

/**
 * Uses flexbox to place a fixed Navbar above a flexible Container.
 * This allows height=100% to work intuitively within the Container, without needing to adjust for the navbar's height.
 */
const ContainerWithNavBar: React.FC<ContainerWithNavBarProps> = ({
  navbar,
  bgImgSrc,
  children,
  className,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,
  backgroundColor,
  backgroundAspectRatio,
  backgroundOpacity,
  backgroundAttachment,
  backgroundClip,
  backgroundOrigin,
  ...containerProps
}) => {
  const theme = useTheme()
  const NavBar = navbar
  return (
    <>
      <BackgroundImage
        src={bgImgSrc}
        position={backgroundPosition || `center ${theme.mixins.toolbar.minHeight}px`}
        repeat={backgroundRepeat}
        size={backgroundSize}
        color={backgroundColor}
        aspectRatio={backgroundAspectRatio}
        opacity={backgroundOpacity}
        attachment={backgroundAttachment}
        clip={backgroundClip}
        origin={backgroundOrigin}
      >
        <VerticalGrid>
          <GridItem>
            <NavBarOffset>
              <NavBar />
            </NavBarOffset>
          </GridItem>
          <GridItem className={className} flexGrow>
            <Container {...containerProps}>{children}</Container>
          </GridItem>
        </VerticalGrid>
      </BackgroundImage>
    </>
  )
}
export default ContainerWithNavBar
