import { Typography } from '@material-ui/core'
import React from 'react'
import ReactMarkdown, { ReactMarkdownOptions } from 'react-markdown'
import { Components } from 'react-markdown/src/ast-to-react'

export interface MarkdownProps extends ReactMarkdownOptions {}

// Use custom components for Markdown-generated tags
const defaultComponents: Components = {
  h1({ children }) {
    return <Typography variant="h1">{children}</Typography>
  },
  h2({ children }) {
    return <Typography variant="h2">{children}</Typography>
  },
  h3({ children }) {
    return <Typography variant="h3">{children}</Typography>
  },
  h4({ children }) {
    return <Typography variant="h4">{children}</Typography>
  },
  h5({ children }) {
    return <Typography variant="h5">{children}</Typography>
  },
  h6({ children }) {
    return <Typography variant="h6">{children}</Typography>
  },
  p({ children }) {
    return <Typography variant="body1">{children}</Typography>
  },
}

/**
 * Wrap ReactMarkdown with typography from the MUI theme.
 */
const Markdown: React.FC<MarkdownProps> = ({ children, components = {} }) => {
  const _components = { ...defaultComponents, ...components }
  return <ReactMarkdown components={_components}>{children}</ReactMarkdown>
}

export default Markdown
