import { Dialog, DialogContent, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'
import VerticallyCentered from '../layout/VerticallyCentered'
import MemoWidgetAvatar from './MemoWidget-Avatar'
import { Memo } from './MemoWidget'
import { useTimeoutFn } from 'react-use'

const DEFAULT_TIMEOUT_MS = 2000 // Auto-hide alert after this duration

export interface MemoWidgetAlertProps {
  timeoutMs?: number
  show?: boolean
  memo?: Memo
  onClose?: () => void
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(26),
    width: theme.spacing(26),
  },
  text: {
    fontSize: '3rem',
    lineHeight: '5rem',
  },
  rounded: {
    borderRadius: 250,
    padding: 12,
  },
  dialog: {
    overflowY: 'hidden',
  },
}))

/**
 * Dialog alert that announces a new MEMo. Automatically hides itself after `timeoutMs`.
 */
const MemoWidgetAlert: React.FC<MemoWidgetAlertProps> = ({ show, memo, timeoutMs, onClose }) => {
  const classes = useStyles()

  // Set a timeout to call `onClose`
  const [_, __, resetTimeout] = useTimeoutFn(() => {
    if (show) {
      onClose && onClose()
    }
  }, timeoutMs || DEFAULT_TIMEOUT_MS)

  // Reset the timeout when a new alert is shown
  React.useEffect(() => {
    if (show) {
      resetTimeout()
    }
  }, [show])

  // Render nothing if no memo is defined
  if (!memo) {
    return <></>
  }

  return (
    <Dialog fullWidth maxWidth="md" open={!!show} onClose={onClose} classes={{ paper: classes.rounded }}>
      <DialogContent classes={{ root: classes.dialog }}>
        <HorizontalGrid alignContent="flex-start" justify="flex-start">
          <GridItem>
            <MemoWidgetAvatar imgSrc={memo.characterAvatarSrc} name={memo.characterName} className={classes.avatar} />
          </GridItem>
          <GridItem>
            <VerticallyCentered>
              <Typography variant="body1" className={classes.text}>
                You‘ve received a new MEMo from <strong>{memo.characterName}</strong>
              </Typography>
            </VerticallyCentered>
          </GridItem>
        </HorizontalGrid>
      </DialogContent>
    </Dialog>
  )
}

export default MemoWidgetAlert
