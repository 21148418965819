import React from 'react'
import clsx from 'clsx'
import { Grid, GridProps, makeStyles } from '@material-ui/core'

export interface GridItemProps extends GridProps {
  flexGrow?: boolean | undefined
  flexShrink?: boolean | undefined
}

const useStyles = makeStyles({
  root: (props: GridItemProps) => ({
    flexGrow: props.flexGrow === true ? 1 : 0,
    flexShrink: props.flexShrink === false ? 0 : 1,
  }),
})

/**
 * Utility wrapper around the MUI Grid item component.
 * This is useful for removing boilerplate in views with several grid items.
 */
const GridItem: React.FC<GridItemProps> = ({ flexGrow, flexShrink, children, className, ...props }) => {
  const classes = useStyles({ flexGrow, flexShrink })
  return (
    <Grid item className={clsx(classes.root, className)} {...props}>
      {children}
    </Grid>
  )
}

export default GridItem
