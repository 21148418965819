import React from 'react'
import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'
import VerticalGrid from '../layout/VerticalGrid'
import MemoWidgetMessageContent from './MemoWidget-MessageContent'
import MemoWidgetPanelTabItem from './MemoWidget-PanelTabItem'
import { Memo } from './MemoWidget'
import { makeStyles } from '@material-ui/core'

export interface MemoWidgetMessagePanelProps {
  memos: ReadonlyArray<Memo>
  className?: string
  onAnyClick?: () => void
  onActiveMemo?: (memoId: number) => void
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'inherit',
  },
  tabs: {
    width: 476,
    flexShrink: 0,
    flexWrap: 'nowrap',
    height: 'inherit',
    overflowY: 'auto',
  },
  messageContent: {
    flexWrap: 'nowrap',
    overflowY: 'auto',
  },
})

/**
 * The Mem-Os Message system. A stacked list of messages is displayed on the left, with the content of the active
 * message rendering on the right.
 */
const MemoWidgetMessagePanel: React.FC<MemoWidgetMessagePanelProps> = ({
  memos,
  className,
  onAnyClick,
  onActiveMemo,
}) => {
  const defaultMemoId = memos.length ? memos[0].id : undefined
  const [activeMsgId, setActiveMsgId] = React.useState<number | undefined>(defaultMemoId)
  const classes = useStyles()

  // Call onActiveMemo whenever activeMsgId is updated
  React.useEffect(() => {
    if (activeMsgId && onActiveMemo) {
      onActiveMemo(activeMsgId)
    }
  }, [activeMsgId])

  return (
    <HorizontalGrid justify="flex-start" className={`${classes.root} ${className}`} spacing={0}>
      {/* Tabs panel */}
      <GridItem className={classes.tabs}>
        <VerticalGrid>
          {memos.map((memo) => (
            <MemoWidgetPanelTabItem
              key={memo.id}
              isActive={activeMsgId === memo.id}
              characterAvatarSrc={memo.characterAvatarSrc}
              characterName={memo.characterName}
              title={memo.title}
              onClick={() => {
                onAnyClick && onAnyClick()
                setActiveMsgId(memo.id)
              }}
            />
          ))}
        </VerticalGrid>
      </GridItem>
      {/* Content panel */}
      <GridItem className={classes.messageContent} flexGrow>
        <MemoWidgetMessageContent memo={memos.find((m) => m.id === activeMsgId)} />
      </GridItem>
    </HorizontalGrid>
  )
}

export default MemoWidgetMessagePanel
