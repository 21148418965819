import React from 'react'
import { Grid, GridProps, makeStyles } from '@material-ui/core'
import { Property } from 'csstype'

export interface VerticalGridProps extends GridProps {
  flexWrap?: Property.FlexWrap
}

const useStyles = makeStyles({
  root: (props: VerticalGridProps) => ({
    height: 'inherit',
    flexDirection: 'column',
    justifyContent: props.justify || 'flex-start',
    alignItems: props.alignItems || 'stretch',
    flexWrap: props.flexWrap || 'nowrap',
  }),
})

/**
 * Utility wrapper around the MUI Grid container component, setup for a vertical layout.
 * This is useful for quickly setting up views with flex-based, stacking rows of content.
 * Pairs nicely with the custom `GridItem` component.
 */
const VerticalGrid: React.FC<VerticalGridProps> = ({ children, flexWrap, ...props }) => {
  const classes = useStyles({ flexWrap, ...props })
  return (
    <Grid className={classes.root} container {...props}>
      {children}
    </Grid>
  )
}
export default VerticalGrid
