import React, { useEffect } from 'react'
import clsx from 'clsx'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { TrackProps } from 'react-player/file'
import { makeStyles } from '@material-ui/core'
import BackgroundImage, { BackgroundImageProps } from '../BackgroundImage'
import Controls from './MediaPlayerControls'
import { SessionContext } from '../../providers/sessionTimer/SessionTimer'

export interface MediaPlayerProps {
  src: string
  width?: number | string
  height?: number | string
  aspectRatio?: number // 100 divided by (width divided by height)
  // TODO handle all react-player props explicitly to prevent overriding critical internal events
  video?: Omit<ReactPlayerProps, 'url'>
  image?: BackgroundImageProps
  className?: string
  controls?: boolean
  englishCaptionsURL?: string
  spanishCaptionsURL?: string
  volume?: number
  onEnded?: () => void
  onReady?: () => void
  onProgress?: (progress: PlayerProgress) => void
  play?: boolean
  enableFullscreen?: boolean
}

export interface PlayerProgress {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

export type Caption = 'en' | 'es' | 'off'

type StyleProps = {
  width?: number | string
  height?: number | string
  aspectRatio?: number
}

const useStyles = makeStyles({
  mediaWrapper: ({ width = '100%', height = '100%', aspectRatio }: StyleProps) => {
    if (!aspectRatio) {
      if (typeof width === 'number' && typeof height === 'number') {
        aspectRatio = 100 / (width / height)
      } else {
        aspectRatio = 100 / (16 / 9) // 16:9 is the most common aspect ratio for HD video
      }
    }
    return {
      position: 'relative',
      paddingTop: `${aspectRatio}%` /* Player ratio: 100 / (1280 / 720) */,
      width: width,
      height,
    }
  },
  mediaPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
})

/**
 * Display an image or video with responsive aspect ratio. Renders nothing if media type cannot be detected.
 * Uses react-player for video playback: https://github.com/CookPete/react-player#props
 */
const MediaPlayer: React.FC<MediaPlayerProps> = ({
  width,
  height,
  aspectRatio,
  src,
  className,
  controls,
  video = {},
  image = {},
  englishCaptionsURL,
  spanishCaptionsURL,
  volume,
  onEnded,
  onProgress,
  onReady,
  play,
  enableFullscreen,
}) => {
  const [playing, setPlaying] = React.useState<boolean>(true)
  const [ready, setReady] = React.useState<boolean>(false)
  const [progress, setProgress] = React.useState<PlayerProgress>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  })
  const [captions, setCaptions] = React.useState<Caption>('en')
  const containerRef = React.useRef(null)
  const videoRef = React.useRef<ReactPlayer>(null)
  const sessionTimer = React.useContext(SessionContext)
  const classes = useStyles({ width, height, aspectRatio })
  const mediaType = getMediaType(src)

  width = width || '100%'
  height = height || '100%'

  useEffect(() => {
    if (play !== undefined) {
      setPlaying(play as boolean)
    }
  }, [play])

  useEffect(() => {
    setReady(false)
  }, [src])

  useEffect(() => {
    playing ? sessionTimer.stop(src) : sessionTimer.start(src)
    return () => sessionTimer.start(src)
  }, [playing])

  useEffect(() => {
    selectCaptions(captions)
  }, [ready, captions])

  const captionTracks: TrackProps[] = [
    {
      kind: 'subtitles',
      src: englishCaptionsURL || '',
      srcLang: 'en',
      label: 'English',
    },
    {
      kind: 'subtitles',
      src: spanishCaptionsURL || '',
      srcLang: 'es',
      label: 'Español',
    },
  ]

  const selectCaptions = (selection: Caption) => {
    if (videoRef?.current && videoRef.current.getInternalPlayer()) {
      const trackList = videoRef.current.getInternalPlayer().textTracks
      const tracks: TextTrack[] = Array.from(trackList)
      tracks.forEach((textTrack: TextTrack, index: number) => {
        trackList[index].mode = 'disabled'
        if (textTrack.language === selection) {
          trackList[index].mode = 'showing'
        }
      })
    }
  }

  if (mediaType === 'video') {
    return (
      <div className={classes.mediaWrapper} ref={containerRef}>
        <ReactPlayer
          ref={videoRef}
          url={src}
          className={clsx(classes.mediaPlayer, className)}
          width={width}
          height={height}
          playing={playing}
          onProgress={(videoProgress) => {
            setProgress(videoProgress)
            if (onProgress) onProgress(videoProgress)
          }}
          progressInterval={200}
          onReady={() => {
            setReady(true)
            if (onReady) onReady()
          }}
          config={{
            file: {
              attributes: {
                crossOrigin: 'true',
              },
              tracks: captionTracks,
            },
          }}
          onEnded={() => {
            sessionTimer.start(src)
            if (onEnded) onEnded()
          }}
          onError={console.error}
          volume={volume || 1}
          {...video}
        />
        {controls && (
          <Controls
            playing={playing}
            setPlaying={setPlaying}
            videoRef={videoRef}
            componentRef={containerRef}
            progress={progress}
            ready={ready}
            enableFullscreen={enableFullscreen}
            englishCaptionsURL={englishCaptionsURL}
            spanishCaptionsURL={spanishCaptionsURL}
            selectCaptions={setCaptions}
          />
        )}
      </div>
    )
  }

  if (mediaType === 'image') {
    return (
      <div className={classes.mediaWrapper}>
        <BackgroundImage src={src} className={clsx(classes.mediaPlayer, className)} {...image} />
      </div>
    )
  }

  return <></>
}

export default MediaPlayer

type GetFileType = (fileName?: string) => 'image' | 'video' | null

/**
 * Determine whether media is an image or video by its file extension.
 */
const getMediaType: GetFileType = (fileName) => {
  fileName = fileName?.toLowerCase()
  if (!fileName) {
    return null
  }
  if (fileName.endsWith('.mp4')) {
    return 'video'
  }
  if (fileName.endsWith('.mov')) {
    return 'video'
  }
  if (fileName.endsWith('.avi')) {
    return 'video'
  }
  if (fileName.toLowerCase().endsWith('.jpg')) {
    return 'image'
  }
  if (fileName.endsWith('.png')) {
    return 'image'
  }
  if (fileName.endsWith('.gif')) {
    return 'image'
  }
  console.warn(`Unable to determine media type for file: ${fileName}`)
  return null
}
