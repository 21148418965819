import { Container as MuiContainer } from '@material-ui/core'
import React from 'react'
import BackgroundImage from '../utils/BackgroundImage'
import { Property } from 'csstype'

const DEFAULT_PADDING_BOTTOM = 3
const DEFAULT_MARGIN_TOP = 3

interface MuiContainerProps {
  disableGutters?: boolean
  fixed?: boolean
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  height?: Property.Height
}

interface BackgroundImageProps {
  backgroundPosition?: Property.BackgroundPosition
  backgroundRepeat?: Property.BackgroundRepeat
  backgroundSize?: Property.BackgroundSize
  backgroundColor?: Property.BackgroundColor
  backgroundAspectRatio?: Property.AspectRatio
  backgroundOpacity?: Property.Opacity
  backgroundAttachment?: Property.BackgroundAttachment
  backgroundClip?: Property.BackgroundClip
  backgroundOrigin?: Property.BackgroundOrigin
}

export interface ContainerProps extends MuiContainerProps, BackgroundImageProps {
  bgImgSrc?: string
}

/**
 * Wraps an @material-ui/core/Container with our own shortcuts for size, padding, and background.
 * https://material-ui.com/api/container/
 */
const Container: React.FC<ContainerProps> = ({
  bgImgSrc,
  disableGutters,
  fixed,
  maxWidth,
  children,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,
  backgroundColor,
  backgroundAspectRatio,
  backgroundOpacity,
  backgroundAttachment,
  backgroundClip,
  backgroundOrigin,
  height,
  ...boxProps
}) => {
  return (
    <BackgroundImage
      src={bgImgSrc}
      position={backgroundPosition}
      repeat={backgroundRepeat}
      size={backgroundSize}
      color={backgroundColor}
      aspectRatio={backgroundAspectRatio}
      opacity={backgroundOpacity}
      attachment={backgroundAttachment}
      clip={backgroundClip}
      origin={backgroundOrigin}
    >
      <MuiContainer
        disableGutters={disableGutters}
        fixed={fixed}
        maxWidth={maxWidth}
        style={{ height: height || '100%' }}
      >
        <div style={{ height: 'inherit' }} {...boxProps}>
          {children}
        </div>
      </MuiContainer>
    </BackgroundImage>
  )
}
export default Container
