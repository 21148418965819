import React from 'react'
import { makeStyles, Theme, Fade, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    width: '85vw',
    height: '85vh',
  },
  modalContainer: {
    position: 'absolute',
    zIndex: theme.zIndex.tooltip,
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(32, 28, 45, 0.9)',
  },
}))

interface Props {
  children?: React.ReactNode
  show: boolean
}

const Modal = ({ children, show }: Props): React.ReactElement => {
  const { modalContent, modalContainer } = useStyles()

  return (
    <Fade timeout={500} in={show}>
      <div className={modalContainer}>
        <Paper className={modalContent} elevation={3}>
          {children}
        </Paper>
      </div>
    </Fade>
  )
}

export default Modal
