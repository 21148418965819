import React from 'react'
import { makeStyles, Typography, Button, Fab } from '@material-ui/core'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'
import VerticalGrid from '../layout/VerticalGrid'
import VerticallyCentered from '../layout/VerticallyCentered'
import { Memo } from './MemoWidget'
import MemoWidgetAvatar from './MemoWidget-Avatar'
import Markdown from '../utils/Markdown'
import MediaPlayer from '../utils/MediaPlayer/MediaPlayer'

export interface MemoWidgetMessagecontentProps {
  memo: Memo | undefined
}

const VIDEO_ASPECT_RATIO = 100 / (16 / 9)

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    paddingTop: 76,
    paddingBottom: 76,
    marginLeft: 40,
    width: 1000,
  },
  memMemoRoot: {
    margin: 0,
    paddingBottom: 76,
    marginLeft: 40,
    width: 1000,
  },
  memMemoBody: {
    marginTop: 9,
    width: 884,
    position: 'relative',
  },
  body: {
    marginTop: 9,
    width: 884,
  },
  avatar: {
    height: 92,
    width: 92,
  },
  title: {
    fontSize: '2.25rem',
    fontWeight: 800,
  },
  paragraph: {
    marginTop: 32,
  },
  videoWrapper: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: 784,
  },
  fab: {
    position: 'fixed',
    top: 30,
    left: 30,
    height: 100,
    width: 100,
    backgroundColor: theme.palette.secondary.dark,
    zIndex: theme.zIndex.tooltip,
    color: 'white',
  },
  fabIcon: {
    height: 80,
    width: 80,
  },
}))

/**
 * The right-hand panel of the mem-os message system that contains the memo body.
 */
const MemoWidgetMessageContent: React.FC<MemoWidgetMessagecontentProps> = ({ memo }) => {
  const classes = useStyles()

  const [memMemoryVideoPlaying, setMemMemoryVideoPlaying] = React.useState<boolean>(false)

  const paragraphs = memo?.body.split('\n').map((p, idx) => (
    <Markdown className={classes.paragraph} key={idx}>
      {p}
    </Markdown>
  ))

  return (
    <VerticalGrid>
      <HorizontalGrid className={classes.root}>
        <GridItem>
          {memo && (
            <MemoWidgetAvatar name={memo?.characterName} imgSrc={memo?.characterAvatarSrc} className={classes.avatar} />
          )}
        </GridItem>
        <GridItem flexGrow className={classes.body}>
          <Typography variant="h4" className={classes.title}>
            {memo?.characterName}
          </Typography>
          <br />
          {memo?.videoUrl && (
            <div className={classes.videoWrapper}>
              <MediaPlayer
                src={memo.videoUrl}
                video={{ aspectRatio: VIDEO_ASPECT_RATIO }}
                controls
                englishCaptionsURL={memo.englishCaptionsURL}
                spanishCaptionsURL={memo.spanishCaptionsURL}
              />
            </div>
          )}
          {paragraphs}
        </GridItem>
      </HorizontalGrid>

      {memo?.memThread && (
        <HorizontalGrid className={classes.memMemoRoot}>
          <GridItem>
            <MemoWidgetAvatar name={memo?.characterName} imgSrc={memo?.characterAvatarSrc} className={classes.avatar} />
          </GridItem>
          <GridItem flexGrow className={classes.memMemoBody}>
            <Typography variant="h4" className={classes.title}>
              {memo?.characterName}
            </Typography>
            <Typography style={{ fontStyle: 'italic' }}>{memo.memThread.label}</Typography>
            {!memo.memThread.isMemory && <br />}
            <HorizontalGrid style={{ justifyContent: 'normal' }}>
              <GridItem>
                {memo.memThread.imgUrl && (
                  <img
                    src={memo.memThread.imgUrl}
                    width={memo.memThread.isMemory ? 400 : 250}
                    height={memo.memThread.isMemory ? 400 : 250}
                    style={memo.memThread.isMemory ? { transform: 'translate(-70px)' } : {}}
                  />
                )}
                {/** For now we must use the combo animation video instead of still **/}
                {!memo.memThread?.isMemory && memo.memThread.videoUrl && (
                  <div style={{ width: 400, marginTop: 10 }}>
                    {/** In this case the video will have no audio **/}
                    <MediaPlayer src={memo.memThread.videoUrl} video={{ aspectRatio: VIDEO_ASPECT_RATIO }} />
                  </div>
                )}
              </GridItem>

              {memo.memThread?.isMemory && memo.memThread.videoUrl && (
                <GridItem>
                  <VerticallyCentered>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{ transform: 'translate(-70px)' }}
                      onClick={() => setMemMemoryVideoPlaying(true)}
                    >
                      <PlayArrowRoundedIcon fontSize="large" />

                      <Typography variant="button">&nbsp; &nbsp;play memory</Typography>
                    </Button>
                  </VerticallyCentered>
                </GridItem>
              )}
            </HorizontalGrid>
          </GridItem>
          {memMemoryVideoPlaying && memo.memThread.videoUrl && (
            <>
              <div style={{ position: 'fixed', top: 0, left: 0 }}>
                <MediaPlayer
                  width={window.innerWidth}
                  height={window.innerHeight}
                  src={memo?.memThread.videoUrl}
                  video={{ aspectRatio: VIDEO_ASPECT_RATIO }}
                  controls
                  enableFullscreen={false}
                  englishCaptionsURL={memo.memThread.englishCaptionsURL}
                  spanishCaptionsURL={memo.memThread.englishCaptionsURL}
                />
              </div>
              <Fab className={classes.fab} onClick={() => setMemMemoryVideoPlaying(false)}>
                <CloseRoundedIcon className={classes.fabIcon} />
              </Fab>
            </>
          )}
        </HorizontalGrid>
      )}
    </VerticalGrid>
  )
}

export default MemoWidgetMessageContent
