import { BoxProps, Button, Typography, useTheme, makeStyles } from '@material-ui/core'
import React from 'react'
import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'
import VerticalGrid from '../layout/VerticalGrid'
import VerticallyCentered from '../layout/VerticallyCentered'

const DEFAULT_CONFIRM_TEXT = 'Yes'
const DEFAULT_DECLINE_TEXT = 'No'

export interface ConfirmDialogProps extends BoxProps {
  text: string
  confirmText?: string
  declineText?: string
  onConfirm: () => void
  onDecline: () => void
}

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '3rem',
    fontWeight: 400,
    lineHeight: '4rem',
    letterSpacing: '0.1rem',
    color: theme.palette.text.primary,
    marginBottom: 80,
  },
  button: {
    width: '100%',
  },
}))

/**
 * Show a dialog with some warning text and yes/no buttons below.
 */
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  text,
  confirmText,
  declineText,
  onConfirm,
  onDecline,
  ...props
}) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <VerticallyCentered style={{ backgroundColor: theme.palette.background.default }}>
      <HorizontalGrid>
        <GridItem flexGrow xs={12} sm={8} md={6}>
          <VerticalGrid {...props}>
            <GridItem flexGrow>
              <Typography variant="h2" className={classes.text} component="h1" align="center">
                {text}
              </Typography>
            </GridItem>
            <GridItem>
              <HorizontalGrid>
                <GridItem flexGrow>
                  <Button variant="outlined" color="secondary" className={classes.button} onClick={onDecline}>
                    {declineText || DEFAULT_DECLINE_TEXT}
                  </Button>
                </GridItem>
                <GridItem flexGrow>
                  <Button variant="contained" color="secondary" className={classes.button} onClick={onConfirm}>
                    {confirmText || DEFAULT_CONFIRM_TEXT}
                  </Button>
                </GridItem>
              </HorizontalGrid>
            </GridItem>
          </VerticalGrid>
        </GridItem>
      </HorizontalGrid>
    </VerticallyCentered>
  )
}

export default ConfirmDialog
