import React from 'react'
import clsx from 'clsx'
import { Grid, GridProps, makeStyles } from '@material-ui/core'
import { Property } from 'csstype'

export interface HorizontalGridProps extends GridProps {
  width?: Property.Width
  height?: Property.Height
  flexWrap?: Property.FlexWrap
}

const useStyles = makeStyles({
  root: (props: HorizontalGridProps) => ({
    flexDirection: 'row',
    justifyContent: props.justify || 'space-evenly',
    alignItems: props.alignItems || 'stretch',
    height: props.height || 'inherit',
    width: props.width || 'inherit',
    flexWrap: props.flexWrap || 'nowrap',
  }),
})

/**
 * Utility wrapper around the MUI Grid container component, setup for a horizontal layout.
 * This is useful for quickly setting up views with flex-based, side-by-side columns of content.
 * Pairs nicely with the `GridItem` component.
 */
const HorizontalGrid: React.FC<HorizontalGridProps> = ({ children, className, flexWrap, ...props }) => {
  const classes = useStyles({ flexWrap, ...props })
  return (
    <Grid
      container
      className={clsx(classes.root, className)}
      spacing={props.spacing === undefined ? 3 : props.spacing}
      {...props}
    >
      {children}
    </Grid>
  )
}
export default HorizontalGrid
