import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
}))

/**
 * Layout helper that produces a div the exact size of the theme's navbar.
 * This is useful to avoid content disappearing behind a floating navbar.
 * https://material-ui.com/components/app-bar/#fixed-placement
 */
const NavBarOffset: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.offset}>{children}</div>
}

export default NavBarOffset
