import { Shader } from 'three'

export const VignetteShader: Shader = {
  uniforms: {
    //@ts-expect-error - this is correct. Wierd any != any error
    tDiffuse: { type: 't', value: null },
    //@ts-expect-error - this is correct. Wierd any != any error
    offset: { type: 'f', value: 1.0 },
    //@ts-expect-error - this is correct. Wierd any != any error
    darkness: { type: 'f', value: 1.4 },
  },

  vertexShader: [
    'varying vec2 vUv;',

    'void main() {',

    'vUv = uv;',
    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

    '}',
  ].join('\n'),

  fragmentShader: [
    'uniform float offset;',
    'uniform float darkness;',

    'uniform sampler2D tDiffuse;',

    'varying vec2 vUv;',

    'void main() {',

    // Eskil's vignette
    'vec4 texel = texture2D( tDiffuse, vUv );',
    'vec2 uv = ( vUv - vec2( 0.5 ) ) * vec2( offset );',
    'gl_FragColor = vec4( mix( texel.rgb, vec3( 1.0 - darkness ), dot( uv, uv ) ), texel.a );',

    '}',
  ].join('\n'),
}
