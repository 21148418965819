import React from 'react'
import GridItem from './GridItem'
import HorizontalGrid, { HorizontalGridProps } from './HorizontalGrid'
import { Property } from 'csstype'
import { makeStyles } from '@material-ui/core'

interface StyleProps {
  width?: Property.Width
  height?: Property.Height
}

export type VerticallyCenteredProps = HorizontalGridProps & StyleProps

const useStyles = makeStyles({
  root: ({ width = '100%', height = '100%' }: StyleProps) => ({
    height,
    width,
  }),
})

/**
 * Center a child element vertically within its parent container.
 * The child element must have a definite size smaller than the parent for this to have any effect.
 */
const VerticallyCentered: React.FC<VerticallyCenteredProps> = ({ width, height, children, ...props }) => {
  const classes = useStyles({ width, height })
  return (
    <HorizontalGrid justify="center" alignItems="center" className={classes.root} spacing={0} {...props}>
      <GridItem>{children}</GridItem>
    </HorizontalGrid>
  )
}
export default VerticallyCentered
