import React from 'react'
import { Avatar, Fab, makeStyles, Badge } from '@material-ui/core'
import { Property } from 'csstype'
import MemoWidgetModalContent from './MemoWidget-ModalContent'
import MemoWidgetAlert from './MemoWidget-Alert'
import memoIconSrc from './assets/memo-icon.png'
import Modal from './MemoWidget-Modal'

const DEFAULT_POSITION = 'fixed'
const DEFAULT_OFFSET_TOP = 12
const DEFAULT_OFFSET_LEFT = 4

export interface Memo {
  id: number
  eosCode: string
  title: string
  body: string
  characterName: string
  updatedAt: string
  characterAvatarSrc?: string
  videoUrl?: string
  englishCaptionsURL?: string
  spanishCaptionsURL?: string
  memThread?: {
    isMemory: boolean
    label: string
    imgUrl?: string
    videoUrl?: string
    englishCaptionsURL?: string
    spanishCaptionsURL?: string
  }
}

interface MemoWidgetStyleProps {
  position?: Property.Position
  offsetTop?: number
  offsetLeft?: number
}

export interface MemoWidgetProps extends MemoWidgetStyleProps {
  memos: ReadonlyArray<Memo>
  numUnread: number
  alertMemo?: Memo
  onCloseAlertMemo?: () => void
  onOpen?: () => void
  onClose?: () => void
  onAnyClick?: () => void // E.g. for playing SFX when any button is tapped
  onActiveMemo?: (memoId: number) => void
}

const useStyles = makeStyles((theme) => ({
  fab: ({ position, offsetTop, offsetLeft }: MemoWidgetStyleProps) => ({
    position: position || DEFAULT_POSITION,
    marginTop: theme.spacing(offsetTop || DEFAULT_OFFSET_TOP),
    marginLeft: theme.spacing(offsetLeft || DEFAULT_OFFSET_LEFT),
    width: 64,
    height: 64,
    zIndex: 1,
    '& .MuiBadge-badge': {
      border: `2px solid ${theme.palette.background.default}`,
      borderRadius: 25,
      fontSize: '1rem',
      height: 25,
      fontWeight: 700,
    },
  }),
  fabIcon: {
    width: 58,
    height: 58,
  },
}))

/**
 * A collapsable widget for showing/hiding the Mem-Os modal view.
 *
 * When collapsed it renders as a floating action button (FAB) with a MEM-O icon.
 * It expands into a modal that contains the entire Mem-Os message system.
 *
 * https://material-ui.com/components/floating-action-button/
 */
const MemoWidget: React.FC<MemoWidgetProps> = ({
  memos,
  alertMemo,
  numUnread,
  onCloseAlertMemo,
  onOpen,
  onClose,
  onAnyClick,
  onActiveMemo,
  ...styleProps
}) => {
  const classes = useStyles(styleProps)
  const [showModal, setShowModal] = React.useState(false)

  // Trigger onOpen handler (if exists)
  React.useEffect(() => {
    if (showModal && onOpen) {
      onOpen()
    }
  }, [showModal])

  function onClickFab(): void {
    onAnyClick && onAnyClick()
    setShowModal(true)
  }

  function _onClose(): void {
    onClose && onClose()
    setShowModal(false)
  }

  return (
    <>
      <MemoWidgetAlert
        show={!!alertMemo}
        memo={alertMemo}
        onClose={() => {
          onCloseAlertMemo && onCloseAlertMemo()
          setShowModal(true)
        }}
      />
      <Modal show={showModal}>
        <MemoWidgetModalContent onClose={_onClose} onAnyClick={onAnyClick} memos={memos} onActiveMemo={onActiveMemo} />
      </Modal>
      <Badge badgeContent={numUnread} color="secondary" className={classes.fab} overlap="circular">
        <Fab color="secondary" onClick={onClickFab}>
          <Avatar src={memoIconSrc} className={classes.fabIcon} />
        </Fab>
      </Badge>
    </>
  )
}

export default MemoWidget
