import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes spinAnimation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  spinner: {
    transformOrigin: 'bottom left',
    position: 'absolute',
    top: 0,
    right: 0,
    animation: '$spinAnimation 1s linear infinite',
  },
  counter: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    fontSize: '150px',
    margin: 0,
  },
  circle: {
    position: 'relative',
  },
  copy: {
    color: 'white',
    fontSize: '150%',
    paddingLeft: '6rem',
    width: '40%',
    letterSpacing: '1px',
  },
  modal: {
    zIndex: theme.zIndex.tooltip + 10,
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(32, 28, 45, 0.9)',
  },
  buttonGroup: {
    display: 'flex',
  },
  endSessionButton: {
    flexGrow: 1,
    margin: '0 0.5rem',
    color: 'white',
    fontWeight: 700,
    padding: '0.5rem 3rem',
    border: '3px solid ' + theme.palette.secondary.main,
  },
}))

export default useStyles
