import { createTheme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import prosaic300SrcWoff2 from '../fonts/prosaic/prosaic-300.woff2'
import prosaic400SrcWoff2 from '../fonts/prosaic/prosaic-400.woff2'
import prosaic700SrcWoff2 from '../fonts/prosaic/prosaic-700.woff2'
import prosaic800SrcWoff2 from '../fonts/prosaic/prosaic-800.woff2'
import prosaic300ItalicSrcWoff2 from '../fonts/prosaic/prosaic-italic-300.woff2'
import prosaic400ItalicSrcWoff2 from '../fonts/prosaic/prosaic-italic-400.woff2'
import prosaic700ItalicSrcWoff2 from '../fonts/prosaic/prosaic-italic-700.woff2'
import prosaic800ItalicSrcWoff2 from '../fonts/prosaic/prosaic-italic-800.woff2'
import ModernDos from '../fonts/ModernDOS8x8.ttf'

// Theme constants
export const DARK_GREY = '#201C2D'
export const DARK_PURPLE = '#312B44'
export const PURPLE = '#51486C'
export const LIGHTEST_PURPLE = '#51486C'
export const YELLOW = '#FAC915'
export const PINK = '#E55D98'
export const DARK_PINK = '#875688'
export const LIGHT_BLUE = '#A0DBE5'

// Load prosaic font
const fonts: Array<CSSProperties> = [
  { weight: 300, style: 'normal', src: prosaic300SrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 400, style: 'normal', src: prosaic400SrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 700, style: 'normal', src: prosaic700SrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 800, style: 'normal', src: prosaic800SrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 300, style: 'italic', src: prosaic300ItalicSrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 400, style: 'italic', src: prosaic400ItalicSrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 700, style: 'italic', src: prosaic700ItalicSrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 800, style: 'italic', src: prosaic800ItalicSrcWoff2, fontFamily: 'prosaic-std' },
  { weight: 300, style: 'normal', src: ModernDos, fontFamily: '"Modern Dos"', format: 'truetype' },
].map((font) => ({
  fontFamily: font.fontFamily,
  fontStyle: font.style,
  fontDisplay: 'auto',
  fontWeight: font.weight,
  src: `url(${font.src}) format('${font.format || 'woff2'}')`,
}))
fonts.push({
  fontFamily: 'Modern Dos',
  fontStyle: 'normal',
  fontDisplay: 'auto',
  fontWeight: 300,
  src: `
    local('Modern Dos'),
    url(${ModernDos}) format('truetype')
  `,
})

/**
 See * https://material-ui.com/customization/theming/
 */
export const kioskThemeConfig = {
  palette: {
    type: 'dark',
    primary: {
      main: YELLOW,
    },
    secondary: {
      main: PINK,
      dark: DARK_PINK,
    },
    background: {
      default: DARK_GREY,
      paper: DARK_PURPLE,
    },
    info: {
      light: LIGHT_BLUE,
      main: LIGHTEST_PURPLE,
      dark: DARK_PURPLE,
    },
  },
  typography: {
    fontFamily: 'prosaic-std, Modern Dos',
    h1: {
      fontSize: '2.25rem',
      fontWeight: 800,
      lineHeight: '3.125rem',
      letterSpacing: 1,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 800,
      lineHeight: '2.5rem',
      letterSpacing: 1,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 800,
      lineHeight: '2.5rem',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '3rem',
      letterSpacing: '0.03rem',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '3rem',
      letterSpacing: '0.03rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '3rem',
      letterSpacing: '0.03rem',
    },
    body1: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '2.5rem',
      letterSpacing: '0.02em',
    },
    body2: {
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '2.5rem',
    },
    button: {
      fontWeight: 800,
      fontSize: '1.125rem',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fonts,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '200px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '38px',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: LIGHTEST_PURPLE,
      },
      paperFullWidth: {
        width: '85%',
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: DARK_PURPLE,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 90,
    },
  },
} as const

const kioskTheme = createTheme(kioskThemeConfig)

export default kioskTheme
