import { makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import { Property } from 'csstype'

export interface BackgroundImageProps {
  src?: string
  position?: Property.BackgroundPosition
  repeat?: Property.BackgroundRepeat
  size?: Property.BackgroundSize
  color?: Property.BackgroundColor
  aspectRatio?: Property.AspectRatio
  opacity?: Property.Opacity
  attachment?: Property.BackgroundAttachment
  clip?: Property.BackgroundClip
  origin?: Property.BackgroundOrigin
  className?: string
  height?: Property.Height
  width?: Property.Width
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const DEFAULT_BG_POSITION = 'center'
const DEFAULT_BG_REPEAT = 'no-repeat'
const DEFAULT_BG_SIZE = 'cover'
const DEFAULT_HEIGHT = '100%'
const DEFAULT_WIDTH = '100%'

const useStyles = makeStyles({
  root: ({
    src,
    position,
    repeat,
    size,
    color,
    aspectRatio,
    opacity,
    attachment,
    clip,
    origin,
    width,
    height,
  }: BackgroundImageProps) => ({
    position: 'inherit',
    backgroundImage: src ? `url(${src})` : undefined,
    backgroundPosition: position || DEFAULT_BG_POSITION,
    backgroundRepeat: repeat || DEFAULT_BG_REPEAT,
    backgroundSize: size || DEFAULT_BG_SIZE,
    backgroundColor: color,
    aspectRatio,
    opacity,
    backgroundAttachment: attachment,
    backgroundClip: clip,
    backgroundOrigin: origin,
    width: width || '100%',
    height: height || '100%',
  }),
})

/**
 * Utility component for filling a parent element with a background image (or color).
 * Either this element's parent or children must have a definite height (not set as a percentage).
 */
const BackgroundImage: React.FC<BackgroundImageProps> = ({
  src,
  height,
  width,
  position,
  repeat,
  size,
  color,
  aspectRatio,
  children,
  opacity,
  className,
  attachment,
  clip,
  origin,
  ...restProps
}) => {
  const classes = useStyles({
    src,
    height,
    width,
    position,
    repeat,
    size,
    color,
    aspectRatio,
    opacity,
    attachment,
    clip,
    origin,
  })
  return (
    <div className={clsx(classes.root, className)} {...restProps}>
      {children}
    </div>
  )
}

export default BackgroundImage
