import React from 'react'
import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'
import MemoWidgetAvatar from './MemoWidget-Avatar'
import { Box, Typography, makeStyles } from '@material-ui/core'

export interface MemoWidgetPanelTabItemProps {
  isActive?: boolean
  characterName: string
  title: string
  characterAvatarSrc?: string
  onClick: () => void
}

const useStyles = makeStyles((theme) => ({
  root: ({ isActive }: { isActive?: boolean }) => ({
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    borderRight: `1px solid ${theme.palette.secondary.dark}`,
    borderLeft: `5px solid ${isActive ? theme.palette.info.light : 'transparent'}`,
    paddingTop: 20,
    paddingBottom: 20,
  }),
  characterName: {
    paddingTop: 10,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '18px',
    lineHeight: '22px',
  },
  messageTitle: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '40px',
    letterSpacing: '0.02em',
  },
  avatar: {
    height: 80,
    width: 80,
    marginLeft: 30,
  },
}))

/**
 * A single vertical tab item in the Mem-Os message layout.
 * Contains an avatar image, who the message is from, and the message title.
 */
const MemoWidgetPanelTabItem: React.FC<MemoWidgetPanelTabItemProps> = ({
  isActive,
  characterAvatarSrc,
  characterName,
  title,
  onClick,
}) => {
  const classes = useStyles({ isActive })

  return (
    <GridItem onClick={() => onClick()} className={classes.root}>
      <Box sx={{ padding: 2 }}>
        <HorizontalGrid justify="flex-start">
          {/* Tab panel item avatar */}
          <GridItem>
            <MemoWidgetAvatar imgSrc={characterAvatarSrc} name={characterName} className={classes.avatar} />
          </GridItem>
          {/* Tab panel item text  */}
          <GridItem flexGrow>
            <Typography className={classes.characterName} variant="body2">
              {truncate(characterName)}
            </Typography>
            <Typography className={classes.messageTitle} variant="body1">
              {truncate(title)}
            </Typography>
          </GridItem>
        </HorizontalGrid>
      </Box>
    </GridItem>
  )
}

export default MemoWidgetPanelTabItem

const truncate = (text: string, numChars = 20): string => {
  if (text.length <= numChars) {
    return text
  }
  return `${text.substring(0, numChars - 1)}…`
}
