import { AppBar, Toolbar, Typography, Grid, AppBarProps, Button, makeStyles } from '@material-ui/core'
import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'

export interface NavBarProps extends AppBarProps {
  title?: string
  goBackText?: string
  onClickMainMenu?: () => void
  onClickEndSession?: () => void
  onClickGoBack?: () => void
}

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    fontWeight: 800,
    lineHeight: '2rem',
    letterSpacing: '0.05rem',
  },
  homeIcon: {
    color: theme.palette.text.primary,
    fontSize: '2.7rem',
  },
  spacerBtn: {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  spacerIcon: {
    fontSize: '1.2rem',
    borderLeft: '1px solid white',
  },
  backIcon: {
    color: theme.palette.primary.main,
    fontSize: '3rem',
  },
}))

/**
 * Wraps a @material-ui/core/AppBar with our own styles and defaults.
 */
const NavBar: React.FC<NavBarProps> = ({
  title,
  onClickMainMenu,
  onClickEndSession,
  onClickGoBack,
  goBackText,
  ...props
}) => {
  const classes = useStyles()
  const position = props.position || 'fixed'

  return (
    <AppBar {...props} position={position} color="default">
      <Toolbar>
        <Grid container direction="row" alignItems="center">
          {/* Left Nav */}
          <GridItem xs={3}>
            {/* Main Menu */}
            {onClickMainMenu && (
              <Button onClick={onClickMainMenu} color="primary">
                <HomeIcon className={classes.homeIcon} />
                {!onClickGoBack && !goBackText && <>&nbsp;&nbsp;&nbsp; Main Menu</>}
              </Button>
            )}
            {/* Go Back */}
            {onClickGoBack && goBackText && (
              <>
                <Button disabled className={classes.spacerBtn}>
                  <span className={classes.spacerIcon}>&nbsp;</span>
                </Button>
                <Button onClick={onClickGoBack} color="primary">
                  <ChevronLeftIcon className={classes.backIcon} />
                  &nbsp; {goBackText}
                </Button>
              </>
            )}
          </GridItem>
          {/* Center Title */}
          <GridItem xs={6}>
            {title && (
              <Typography variant="h5" component="h1" align="center" className={classes.title}>
                {title}
              </Typography>
            )}
          </GridItem>
          {/* Right Nav */}
          <GridItem xs={3}>
            {onClickEndSession && (
              <HorizontalGrid justify="flex-end">
                <GridItem>
                  <Button onClick={onClickEndSession} color="primary">
                    End Session
                  </Button>
                </GridItem>
              </HorizontalGrid>
            )}
          </GridItem>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
export default NavBar
