import React from 'react'
import { CssBaseline, Theme, ThemeProvider as MuiThemeProvider, useTheme } from '@material-ui/core'

export interface ThemeProviderProps {
  theme?: Theme
}

/**
 * Application theme wrapper to initialize styles and theme.
 * This should usually be the first component in your app.
 */
const ThemeProvider: React.FC<ThemeProviderProps> = ({ theme, children }) => {
  const defaultTheme = useTheme()
  if (!theme) {
    theme = defaultTheme
  }
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </div>
  )
}
export default ThemeProvider
