import React from 'react'
import { ChevronRight } from '@material-ui/icons'
import { Drawer, DrawerProps, makeStyles, Toolbar } from '@material-ui/core'

import GridItem from '../layout/GridItem'
import HorizontalGrid from '../layout/HorizontalGrid'
import VerticallyCentered from '../layout/VerticallyCentered'

export interface SideBarProps extends DrawerProps {
  width?: string | number
  open: boolean
  onClose: () => void
}

export const DEFAULT_SIDEBAR_WIDTH_PX = 567

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.appBar - 1,
    '& .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight': {
      backgroundColor: 'inherit',
      boxShadow: 'inherit',
      overflow: 'hidden',
    },
  },
  inner: {
    overflow: 'hidden',
  },
  content: ({ width }: { width: string | number }) => ({
    width,
    backgroundColor: theme.palette.info.dark,
    height: '100%',
    padding: 0,
    margin: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
  }),
  closeBtn: {
    width: 90,
    height: 90,
    backgroundColor: theme.palette.info.main,
  },
  chevron: {
    fontSize: '5rem',
  },
}))

/**
 * A generic, collapsable sidebar drawer that slides in from the right.
 */
const SideBar: React.FC<SideBarProps> = ({ open, onClose, width, children, ...props }) => {
  const classes = useStyles({ width: width || DEFAULT_SIDEBAR_WIDTH_PX })
  return (
    <Drawer anchor="right" className={classes.root} open={open} onClose={onClose} {...props}>
      <Toolbar />
      <HorizontalGrid spacing={0} className={classes.inner}>
        <GridItem>
          <div className={classes.closeBtn} onClick={onClose}>
            <VerticallyCentered spacing={0} style={{ lineHeight: 0 }}>
              <ChevronRight className={classes.chevron} />
            </VerticallyCentered>
          </div>
        </GridItem>
        <GridItem className={classes.content} flexGrow>
          {children}
        </GridItem>
      </HorizontalGrid>
    </Drawer>
  )
}

export default SideBar
