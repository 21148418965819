import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  controlsContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  controls: {
    width: '100%',
  },
  transport: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: '10px 2% 0 2%',
    position: 'relative',
  },
  button: {
    background: 'none',
    border: 'none',
    height: '80%',
    width: '10%',
  },
  icon: {
    height: '100%',
    width: '100%',
  },
  spacer: {
    flexGrow: 1,
  },
  languageSelector: {
    position: 'absolute',
    bottom: '100%',
    right: 0,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem',
  },
  languageButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    padding: '0.5rem 1rem',
  },
}))

export const sliderStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.info.light,
    padding: 0,
    height: '30px',
    marginBottom: '-32px',
    boxSizing: 'border-box',
    backGroundColor: 'transparent',
    zIndex: 1,
  },
  rail: {
    height: '6px',
    top: '12px',
    backGroundColor: theme.palette.secondary.dark,
  },
  track: {
    height: '6px',
    top: '12px',
  },
  thumb: {
    display: 'none',
  },
}))

export default useStyles
