import { Box, BoxProps, styled } from '@material-ui/core'
import React from 'react'

export type ViewportBoxProps = BoxProps

const _ViewportBox = styled(Box)({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  margin: 0,
  padding: 0,
})

/**
 * A simple div component fixed to the full width and height of the browser viewport.
 * This is a useful top-level component for views that never scroll.
 */
const ViewportBox: React.FC<ViewportBoxProps> = ({ children, ...props }) => {
  return <_ViewportBox {...props}>{children}</_ViewportBox>
}
export default ViewportBox
